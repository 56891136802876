import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { FaAngleDoubleDown } from "react-icons/fa";

import { Link } from 'gatsby';
import { Title, Twitter, Testonimal } from "../../components/Globals";
import Iframe from 'react-iframe';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import "./awesome-slider-override.css";
const ProcurementConsultingPage = () => {
    
    const ref = React.createRef();

  const arrowClick = () =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    return (
        <Layout>
        <Helmet>
        <title>Procurement Consulting Services | Cymetrix Software</title>
        <meta name="description" content="Cymetrix offers strategic procurement consulting services augmented by AI to help procurement organizations to rapidly achieve more efficient, more effective operations for improved busienss impact."/>
      <meta name="keywords" content="cymetrix,procurent consulting, procurement advisors, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
      <link rel="canonical" href="https://www.cymetrixsoft.com/services/procurement-consulting/" />
      </Helmet>
            <div className="procurement-background h-screen flex flex-col">
            <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                  SECURE THE DIGITAL TRANSFORMATION OF YOUR BUSINESS
                    </h1>
                <button class="focus:outline-none " >
         <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
        </button>
            </div>
            <Title title="PROCUREMENT CONSULTING SERVICE"/ >
            <div className="relative max-w-6xl mx-auto" ref={ref}>
              <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
                  <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-lef">
                
                  <p className="font-hairline text-justify">Spend Analysis for procurement is the way in which we process, cleanse, classify, and analyze the expenditure data with the sole purpose of decreasing procurement costs. The improved efficiency and monitored controlling and compliance are the byproducts of spend analysis. <br /><br />
    
                  We Cymetrix Software are an offshore Salesforce Development Company and procurement services provider that have helped numerous clients in their major routine services procurement solutions projects which involved, category management, and supply management. We have been able to deliver on sourcing, contracts, procurements, and invoicing. Not only this, but we also have industrial experience in supply chain management which enables us to hold quality management for you.			 

                  </p>
                  <div className="flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8">
                      <Link to="/about/company" className="uppercase font-bold px-8 lg:px-10 py-3 rounded bg-gray-500 text-gray-100 hover:bg-hover focus:shadow-outline focus:outline-none transition duration-300">
                      About Cymetrix
                      </Link>
                  </div>
                  </div>
                  <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                  <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/cymetrix-salesforce-consulting-company.jpg')}
                      alt="Cymterix  Software's Procurement Consulting Service"
                      title=" Strategic Sourcing and Procurement Consulting  "
                      />
                  </div>
                  </div>
              </div>
            </div>
            <div className="px-16 py-10 bg-gray-400 text-gray-700 max-w-6xl mx-auto">
                <h4 class="text-lg"><b><Link to="/services/spend-analysis" class="underline text-blue-900">Spend Analysis Deliverables</Link></b></h4>
                <p> Data Collection which involves data identification and extraction. We also help you with data cleansing, data enrichment, data categorization, and ultimately <Link to="/services/data-analytics" class="underline text-blue-700"> data analysis</Link>.</p>
            </div>
            <div className="flex flex-col mx-auto h-70 max-w-6xl my-5 mt-3 py-3">
            <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5 "/>
            <Twitter />

            <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
            </div>
        
          </Layout>
        );
}
export default  ProcurementConsultingPage;